import Service from "../Service";

const resource = "householdallowance/";

export default {
    get(hac, requestID) {
        return Service.post(resource, hac, {
            params: { requestID: requestID },
        });
    },
    getparameter(requestID) {
        return Service.post(resource + "parameter", {}, {
            params: { requestID: requestID },
        });
    },


    save(hac, requestID) {
        return Service.post(resource + "save", hac, {
            params: { requestID: requestID },
        });
    },

    pagination(parameters, requestID) {
        return Service.post(resource + "pagination", parameters, {
            params: {
                requestID: requestID,
            },
        });
    },

    report(obj, requestID) {
        return Service.post(resource + "report", obj, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
            params: { tp: obj.tp, id: obj.id, init: obj.init, end: obj.end },
        });
    },
};