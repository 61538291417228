<template>
  <div>
    <s-toolbar @save="saveDocument($event)" @close="close()" save close label="Administracion Documentos">
      <v-tooltip bottom="">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" small @click="downloadFile()"> <v-icon style="font-size:16px;">fas fa-download</v-icon></v-btn>
        </template>
        <span>Descargar</span></v-tooltip
      >
      <v-tooltip bottom="">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" small @click="deleteDocument()"> <v-icon style="font-size:16px;">mdi-minus-circle-outline</v-icon></v-btn>
        </template>
        <span>Eliminar</span></v-tooltip
      >
    </s-toolbar>
    <v-card>
      <v-card-text>
        <v-col cols="12" class="s-col-form">
          <s-load-file @changeData="changeData($event)" />
        </v-col>
      </v-card-text>
    </v-card>
    <s-crud noToolbar title="Manejo de Archivos" :config="config" :filter="filter" @rowSelected="rowSelected($event)" ref="scrudarchive"></s-crud>
  </div>
</template>
<script>
import _sDocument from "@/services/HumanResource/HouseHoldAllowanceDocumentService";
import _sHelper from "@/services/HelperService";
export default {
  components: {},
  props: {
    value: {
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      filter: {
        HadID: 0,
      },
      config: {
        model: {
          HdcID: "ID",
          HadID: "int",
          HdcLocation: "string",
          HdcObservation: "string",
          HdcDate: "date",
        },

        service: _sDocument,
        headers: [
          {
            width: "5%",
            text: "ID",
            value: "HdcID",
          },
          {
            text: "Archivo",
            value: "HdcLocation",
          },
          {
            width: "25%",
            text: "Fecha",
            value: "HdcDate",
          },
        ],
      },
      hdc: null,
    };
  },
  watch: {
    value() {
      this.filter.HadID = this.value;
      this.initialize();
    },
  },

  methods: {
    initialize() {
      console.log(this.filter);
      this.$nextTick().then(() => {
        this.hdc.HadID = this.value;
      });
    },

    rowSelected(items) {
      if (items.length > 0) {
        this.hdc = items[0];
      } else this.hdc = {};
    },
    saveDocument(item) {
      if (this.files != null)
        this.$fun.alert("Seguro de subir Archivo", "question").then((val) => {
          if (val.value) {
            if (this.config != null)
              if (this.files!=null) {
                var formData = new FormData();
                formData.append("file", this.files);

                _sHelper
                  .uploadfile(this.filter.HadID, this.$fun.getUserID(), formData, 2)

                  .then(() => {
                    this.$fun.alert("Archivo cargado correctamente", "success");
                    this.files = null;
                  });
              } else this.$fun.alert("Seleccione Archivo a Cargar", "info");

            var hdc = {
              HadID: this.value,
              HdcLocation: this.files[0].name,
              HdcObservation: "Subida Archivo de Asignacion",
              SecStatus: 1,
              UsrCreateID: this.$fun.getUserID(),
            };

            this.saveTransaction(hdc);

            this.RefresTransaction();
          }
        });

      //guardando documentos
    },
    saveTransaction(document) {
      console.log(document);
      _sDocument.save(document, this.$fun.getUserID()).then((response) => {
        if (response.status == 200) {
          let obj = Object.assign({}, response);
          if (document.HdcID == 0) {
            this.$fun.alert("Agregado Correctamente", "success");
          } else {
            this.$fun.alert("Actualizado Correctamente", "success");
          }
        } else {
          _sHelper.delete(document.HadID, this.$fun.getUserID(), 2, document.HdcLocation).then(() => {
            this.$fun.alert("Archivo eliminado correctamente", "success");
          });
        }
      });
    },

    RefresTransaction() {
      this.$refs.scrudarchive.refresh();
    },
    deleteDocument() {
      this.$fun.alert("Seguro de eliminar Archivo", "question").then((val) => {
        console.log(this.hdc);
        if (val.value) {
          _sHelper.delete(this.hdc.HadID, this.$fun.getUserID(), 2, this.hdc.HdcLocation).then(() => {
            this.hdc.SecStatus = 0;
            this.saveTransaction(this.hdc);
            this.$fun.alert("Archivo eliminado correctamente", "success");
            this.RefresTransaction();
          });
        } else this.$fun.alert("error", "info");
      });
    },
    //
    changeData(item) {
      this.files = item;
    },
    downloadFile() {
      console.log(this.hdc);
      if (this.hdc.HdcLocation != null) {
        console.log(this.hdc.HadID);
        _sHelper.getfile(this.hdc.HadID, this.$fun.getUserID(), this.hdc.HdcLocation, 2).then((r) => {
          //this.$fun.downloadFile(r.data, this.$const.TypeFile.PDF, null);
          const url = window.URL.createObjectURL(new Blob([r.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", this.hdc.HdcLocation); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      }
    },

    //
    close() {
      this.$emit("close");
    },
  },
};
</script>
