<template>
  <v-row>
    <v-col cols="12" md="5">
      <s-crud title="Asignaciones Familiares" :config="this.config" @rowSelected="rowSelected($event)" sortable remove ref="crudHac" searchInput>
        <template v-slot:CtrStatus="{ row }">
          <v-icon :color="row.CtrStatus == 1 ? 'success' : 'red'"> mdi-checkbox-blank-circle</v-icon>
        </template>
      </s-crud>
    </v-col>
    <v-col cols="12" md="7">
      <s-crud
        title="Asignación Familiar Detalle"
        :config="hadconfig"
        :filter="filter"
        @rowSelected="rowSelectedHad($event)"
        @save="saveValidate($event, hac)"
        formPermanent
        remove
        ref="crudHad"
        noConfirmationSave
      >
        <template scope="props">
          <v-container>
            <v-row
              ><v-col lg="12"> <v-label>Datos Personal /Contrato</v-label></v-col></v-row
            >
            <v-row>
              <v-col cols="2" class="s-col-form">
                <s-text label="ID" disabled v-model="hac.WkrID" />
              </v-col>
              <v-col cols="4" class="s-col-form">
                <s-select-definition :def="1060" v-model="hac.TypePayroll" label="Tipo Planilla" disabled />
              </v-col>
              <v-col cols="6" class="s-col-form">
                <s-text label="Trabajador" disabled v-model="hac.WkrName" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="s-col-form"> <s-date label="F.Ingreso" disabled v-model="hac.CtrAdmissionDate" /> </v-col>
              <v-col cols="3" class="s-col-form"> <s-date label="Inicio Contrato" disabled v-model="hac.CtrBeginDate" /> </v-col>
              <v-col cols="3" class="s-col-form"> <s-date label="Fin Contrato" disabled v-model="hac.CtrEndDate" /> </v-col>
              <v-col cols="3" class="s-col-form"> <s-text label="Contrato" disabled v-model="hac.CtrStatusName" /> </v-col>
            </v-row>
            <v-row
              ><v-col lg="12"> <v-label>Asignación</v-label></v-col></v-row
            >
            <v-row>
              <v-col cols="9" class="s-col-form">
                <s-text label="Observación" v-model="hac.HacObservation" />
              </v-col>
              <v-col cols="3" class="s-col-form">
                <s-date label="F.Registro" v-model="hac.HacPayDate" />
              </v-col>
            </v-row>
            <v-row
              ><v-col lg="12"> <v-label>Beneficiarios</v-label></v-col></v-row
            >
            <v-row>
              <v-col style="padding-bottom: 0px; padding-top: 0px" lg="12">
                <v-radio-group v-model="TypeRelation" row>
                  <v-radio label="Hijo" value="1"></v-radio>
                  <v-radio label="Cónyuge" value="2"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row> 
              <v-col cols="3" class="s-col-form">
                <s-toolbar-person
                  v-model="props.item.PrsDocumentNumber"
                  @returnPerson="hadReturnPerson($event, props.item)"
                  addBirthDate
                  addTypeSex
                />
              </v-col>
              <v-col cols="5" class="s-col-form">
                <s-text disabled label="Beneficiado(a)" v-model="props.item.NtpFullName" />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <s-text disabled label="Edad" v-model="props.item.NtpYear" />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <s-text disabled label="Sexo" v-model="props.item.TypeSexText" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" class="s-col-form">
                <s-text label="Observación" v-model="props.item.HadObservation" />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <v-switch v-model="props.item.HadException" disabled hide-details="" v-if="TypeRelation == 1" label="Excepción" class="mt-2" />
              </v-col>
              <v-col cols="2" class="s-col-form">
                <v-btn @click="AddDocument" :color="getColor(props.item.HadHaveDocument)" v-if="props.item.HadID > 0">
                  <v-icon x-small v-if="props.item.HadHaveDocument == 0" left>fas fa-upload</v-icon>
                  <v-icon x-small v-else left>fas fa-eye</v-icon>
                  ...
                </v-btn>
              </v-col>
              <v-col cols="3" class="s-col-form" v-if="$fun.getSecurity().IsLevelAdmin || props.item.HadStatus != 1">
                <s-select-definition :def="1061" v-model="props.item.HadStatus" label="Estado" :disabled="modifystatus" />
              </v-col>
              <v-col cols="9" class="s-col-form" v-if="$fun.getSecurity().IsLevelAdmin || props.item.HadStatus != 1">
                <s-text label="Motivo de Estado" v-model="props.item.HadObservationApproval" :disabled="modifystatus" />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </s-crud>
    </v-col>

    <v-dialog max-width="900" v-model="ViewDocument" persistent>
      <allowance-document @save="save()" @close="close()" v-model="HadID" />
    </v-dialog>
  </v-row>
</template>

<script>
import _sHouseHoldAllowance from "@/services/HumanResource/HouseholdAllowanceService";
import _sHouseHoldAllowanceDetail from "@/services/HumanResource/HouseHoldAllowanceDetailService";
import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";
import AllowanceDocument from "@/views/HumanResource/HouseHoldAllowance/AllowanceDocument";

export default {
  name: "HouseHoldAllowance",
  components: {
    sToolbarPerson,
    AllowanceDocument,
  },
  data: () => ({
    filter: {
      CtrStatus: 1,
    },
    hac: {
      TypePayroll: 0,
    }, // Objecto para almacenar el valor encontrado seleccionado
    config: {
      model: {
        WkrID: "ID",
        WkrName: "string",
        PrsDocumentNumber: "string",
        CtrStatus: "",
        HacID: "int",
      },
      title: "Asignaciones",
      service: _sHouseHoldAllowance,
      headers: [
        {
          text: "ID",
          width: "5%",
          value: "WkrID",
          sorteable: true,
        },
        {
          text: "HacID",
          width: "15%",
          value: "HacID",
          sorteable: true,
        },
        {
          text: "N°Doc",
          value: "PrsDocumentNumber",
          sorteable: true,
        },
        {
          text: "Trabajador",
          value: "WkrName",
          sorteable: true,
        },
        {
          width: "20%",
          align: "center",
          text: "Contrato",
          value: "CtrStatus",
          sorteable: true,
        },
      ],
    },
    hadconfig: {
      service: _sHouseHoldAllowanceDetail,
      model: {
        HadID: "ID",
        HacID: "int",
        NtpID: "int",
        TypeRelation: "int",
        HadStatus: "int",
        HadObservation: "string",
        HadException: "int",
        HadObservationApproval: "string",
        NtpYear: "int",
        TypeSex: "string",
        TypeSexText: "string",
      },
      headers: [
        {
          text: "ID",
          value: "HadID",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Tipo",
          value: "TypeRelationText",
          width: "5%",
          align: "center",
          sorteable: true,
        },
        {
          text: "Nombre",
          value: "NtpFullName",
          sorteable: true,
        },
        {
          text: "Edad",
          value: "NtpYear",
          width: "5%",
          align: "end",
          sorteable: true,
        },
        {
          text: "Sexo",
          value: "TypeSexText",
          width: "5%",
          align: "center",
          sorteable: true,
        },

        {
          text: "Estado",
          value: "HadStatusText",
          width: "5%",
          align: "center",
          sorteable: false,
        },
      ],
    },
    //detail
    tab: "tab-show",
    filter: {
      HacID: 0,
      WkrID: 0,
    },
    register: false,
    modify: true,
    modifystatus: true,
    person: null,
    TypeRelation: "1",
    returnregister: 0,
    EdadAsignacionFamiliar: 0,
    EdadAsignacionFamiliarExcepcion: 0,
    ViewDocument: false,
    HadID: 0,
  }),
  created() {
    this.hac.HacID = this.filter.HacID;
    this.initialize();
  },

  methods: {
    initialize() {
      this.$nextTick().then(() => {
        if (this.filter != null && this.filter.WkrID > 0) {
          _sHouseHoldAllowance.get(this.filter, this.$fun.getUserID()).then((x) => {
            this.hac = x.data;
            // console.log(this.hac)
          });
          this.modify = true;
        } else
          this.hac = {
            TypePayroll: 5,
            HacObservation: "",
            WkrName: "",
            HacID: 0,
            HacPayDate: this.$fun.getDate(),
            SecStatus: 1,
          };

        _sHouseHoldAllowance.getparameter(this.$fun.getUserID()).then((x) => {
          this.EdadAsignacionFamiliar = x.data.EdadAsignacionFamiliar;
          this.EdadAsignacionFamiliarExcepcion = x.data.EdadAsignacionFamiliarExcepcion;
        });
      });
    },
    getColor(status) {
      console.log(status);
      if (status == 0) return "";
      //activo
      else if (status == 1) return "warning";
      // baja
    },
    isValidateBenefitedRestriction(relation, years) {
      let isValid;
      let message = "";

      if (relation == "1") {
        //Validacion para el tipo de hijo

        //Los beneficiados deben ser menor a 18 años
        if (years < this.EdadAsignacionFamiliar)
          // Validacion de menores de edad

          isValid = true;
        else {
          isValid = years >= this.EdadAsignacionFamiliar && years <= this.EdadAsignacionFamiliarExcepcion;

          if (isValid)
            message =
              "El beneficiado; se encuentra dentro de los parametros establecidos: " +
              this.EdadAsignacionFamiliar +
              "-" +
              this.EdadAsignacionFamiliarExcepcion;
          else {
            isValid = false;
            message = "El beneficiado; no se puede registrar debido a que excede los parametros establecidos";
          }
        }
        if (message.length > 0) this.$fun.alert(message, "warning");

        return isValid;
      }
    },
    isStatusBeneficed(relation, years, type) {
      //type define el valor a devolver
      // 1:Excepcion -- 2:Estado de registro -- 3:Mensaje
      let isValid;
      let isValue; // 1:Pendiente -- 2:Aprobado -- 3:Observado --4:Rechazado
      let isException; // 1:true -- 2:false
      if (relation == "1") {
        if (years < this.EdadAsignacionFamiliar) {
          isValue = 2;
          isException = 0;
        } else {
          isValid = years >= this.EdadAsignacionFamiliar && years <= this.EdadAsignacionFamiliarExcepcion;
          isValue = 1;
          isException = 1;
        }
      } else isValue = 2; //

      if (type == 1) return isValue;
      else return isException;
    },

    hadReturnPerson(value, item) {
      if (value != null) {
        item.NtpFullName = value.NtpFullName;
        item.NtpID = value.NtpID;
        item.WkrID = value.WkrID;
        item.NtpYear = this.$moment().diff(this.$moment(value.NtpBirthDate, "YYYY-MM-DD"), "years");

        item.TypeSexText = value.TypeSexText;

        //Validacion para adicion de restricciones
        var returnvalidate = this.isValidateBenefitedRestriction(this.TypeRelation, item.NtpYear);
        console.log(returnvalidate);
        if (!returnvalidate) this.$fun.alert("El beneficiado excede el limite de edad", "warning");
        item.HadException = this.isStatusBeneficed(this.TypeRelation, item.NtpYear, 2);
        item.HadStatus = this.isStatusBeneficed(this.TypeRelation, item.NtpYear, 1);
        item.HadObservation =
          this.isStatusBeneficed(this.TypeRelation, item.NtpYear, 2) == 0 ? "" : "Beneficiado con excepción registrada, favor aprobar";
        item.HadObservationApproval = item.HadObservationApproval;
        console.log(item.HacPayDate);
        console.log(item.HadStatus);
      }

      //if (value.NtpID > 0) this.iinCallSave();
    },

    rowSelected(items) {
      if (items.length > 0) {
        //this.hac.HacID = items[0].HacID;
        //this.hac.WkrID = items[0].WkrID;
        this.filter.HacID = items[0].HacID;
        this.filter.WkrID = items[0].WkrID;

        if (this.filter != null) this.initialize();
        this.$refs.crudHad.refresh();
      }
    },
    rowSelectedHad(hadselect) {
      if (hadselect.length > 0 && hadselect != null) {
        console.log(hadselect);
        this.TypeRelation = hadselect[0].TypeRelation == 1 ? "1" : "2";
        this.register = true;
        this.modifystatus = !this.$fun.getSecurity().IsLevelAdmin;
        this.HadID = hadselect[0].HadID;
      } else {
        this.register = false;
        this.modifystatus = false;
      }
    },
    //validando
    isValidateWorker(worker) {
      let isValid;
      let message;

      isValid = worker.WkrID > 0;
      if (!isValid) message = "Seleccione trabajador";

      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },
    isValidateBenefited(benefited) {
      let isValid;
      let message;

      isValid = benefited.NtpID > 0;
      if (!isValid) message = "Registre datos del beneficiado(a)";
      if (!isValid) this.$fun.alert(message, "warning");

      return isValid;
    },

    //Guardar Datos
    saveValidate(value, item) {
      if (item != null) {
        //Validamos que selecciones un trabajador
        if (this.isValidateWorker(item)) {
          this.saveHac(item, value);
        }
      }
    },

    saveHac(item, benefited) {
      var hac = {};
      if (benefited != null && benefited.NtpID > 0) {
        // registrara cabecera y detalle

        if (this.isValidateBenefited(benefited)) {
          //console.log("isValidateBenefited");
          benefited.TypeRelation = this.TypeRelation;
          if (this.isValidateBenefitedRestriction(this.TypeRelation, benefited.NtpYear)) {
            //alert("paso validaciones");
          } else {
            return;
          }
        }

        hac = {
          HacID: item.HacID,
          WkrID: item.WkrID,
          HacChildrenQuantity: 0,
          HacObservation: item.HacObservation,
          HacException: 0,
          UsrID: this.$fun.getUserID(),
          SecStatus: 1,
          HacPayDate: item.HacPayDate,
          HmnHouseholdAllowanceDetail: [
            {
              HadID: benefited.HadID,
              HacID: item.HacID,
              NtpID: benefited.NtpID,
              HadObservationApproval: benefited.HadObservationApproval,
              HadStatus: benefited.HadStatus,
              TypeRelation: benefited.TypeRelation,
              HadHaveDocument: benefited.HadException,
              HadObservation: benefited.HadObservation,
              HadException: benefited.HadException,
              UsrID: this.$fun.getUserID(),
              UsrCreateID: this.$fun.getUserID(),
              UsrUpdateID: this.$fun.getUserID(),
              SecStatus: 1,
            },
          ],
        };
      } else {
        hac = {
          HacID: item.HacID,
          WkrID: item.WkrID,
          HacChildrenQuantity: 0,
          HacObservation: item.HacObservation,
          HacException: 0,
          UsrID: this.$fun.getUserID(),
          SecStatus: 1,
          HacPayDate: item.HacPayDate,
          HmnHouseholdAllowanceDetail: {},
        };
      }

      _sHouseHoldAllowance.save(hac, this.$fun.getUserID()).then((response) => {
        if (response.status == 200) {
          item.HacID = response.data.HacID;
          if (item.HacID == 0) {
            this.$fun.alert("Agregado Correctamente", "success");
          } else {
            //this.$refs.crudHad.refresh();
            this.$fun.alert("Actualizado Correctamente", "success");
          }
        }
      });

      this.$refs.crudHac.refresh();
    },

    //Validaciones

    saveHad(item, HacID) {
      if (this.register) {
        if (this.isValidateBenefited(item)) {
          console.log("isValidateBenefited");
          if (this.isValidateBenefitedRestriction(item)) {
            console.log("isValidateBenefitedRestriction");
            item.NtpID = item.NtpID;
            item.HadObservationApproval = item.HadObservationApproval;
            item.HadStatus = item.HadStatus;
            item.HacID = HacID;
            item.TypeRelation = item.TypeRelation;
            item.HadHaveDocument = item.HadException;
            item.HadObservation = item.HadObservation;
            item.HadException = item.HadException;
            item.UsrID = this.$fun.getUserID();
            item.SecStatus = 1;
            item.save();
          }
          //isValidateBenefitedRestriction
        }
      }
    },

    //Vizualicion de documentos
    AddDocument() {
      this.ViewDocument = true;
      //this.HadID =
    },
    close() {
      this.ViewDocument = false;
    },
  },
};
</script>
