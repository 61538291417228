import Service from "../Service";

const resource = "householdallowance/";

export default {
  save(adc, requestID) {
    return Service.post(resource + "savedocument", adc, {
      params: { requestID: requestID },
    });
  },

  pagination(dtr, requestID) {
    return Service.post(resource + "documentpagination", dtr, {
      params: { requestID: requestID },
    });
  },
};
